import React, { useEffect, useState } from "react"
import { AddAggregationToData, Bot, BotData, BotMetaData, errorsAggregation, httpInstance } from "../Logic/AppState"
import dayjs from "dayjs"
import logo from "../logo_indosuez.svg"
import SelectComponent, { Option } from "./Select"
import { months, years } from "../Constant"
import ErrorsFocus from "./ErrorsFocus"
import IterationStatus from "./IterationStatus"
import { Unknown } from "./Unknown"
import HelpMenu from "./HelpMenu"

const emptyDatas: BotData[] = [
  {
    month: "",
    year: "",
    scheduled: 0,
    executed: 0,
    succeeded: 0,
    failed: 0,
    stopped: 0,
    suspended: 0,
    resumed: 0,
    averageExecutionTime: 0,
    quartileExecutionTimes: {
      quartile25: 0,
      quartile50: 0,
      quartile75: 0,
      quartile100: 0,
    },
    errors: [],
  },
]

export function Home(props: { userData: Bot[] | undefined; setUserData: Function; userId: String }) {
  const { userData, setUserData, userId } = props
  const [errorView, setErrorView] = useState(false)
  const [botName, setBotName] = useState<string>("Tous les robots")
  const [backVersion, setBackVersion] = useState<string>("")

  let botList: Option[] = []

  if (userData) {
    botList = userData.map((bot) => {
      return { value: bot.botName, label: bot.botName }
    })
  }

  const [month, setMonth] = useState<string>(dayjs().subtract(1, "month").month().toString())
  const [year, setYear] = useState<string>(dayjs().year().toString())

  useEffect(() => {
    const getData = async () => {
      const back = await httpInstance.get("/")

      if (back?.data?.version) {
        setBackVersion(back.data.version)
      }

      if (userId) {
        const res = await httpInstance.get(`/user/${userId}/bot/data?&month=${parseInt(month) + 1}&year=${year}`)

        AddAggregationToData(res.data.data)

        setUserData(res.data.data)
      }
    }

    getData().catch(console.error)
  }, [month, year, userId, setUserData])

  function handleBtnClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault()
    const elem = event.target as HTMLElement

    if (elem.innerText === "Focus erreurs") {
      setErrorView(true)
    } else {
      setErrorView(false)
    }
  }

  const frontVersion = process.env.REACT_APP_HASH

  return (
    <>
      {userData ? (
        <div className="app">
          <img src={logo} className="app-logo" alt="logo" />
          <div className="page-bg">
            <h1 className="app-title">Reporting mensuel d'activité RPA</h1>
            <nav>
              <div className="select-bot">
                <div className="select-bot-date">
                  <SelectComponent
                    options={months}
                    defaultValue={{ value: `${month}`, label: dayjs().format("MMMM") }}
                    secondary
                    width={155}
                    handler={setMonth as (value: string) => void}
                  ></SelectComponent>
                  <SelectComponent
                    options={years}
                    defaultValue={years[years.length - 1]}
                    secondary
                    width={100}
                    handler={setYear}
                  ></SelectComponent>
                </div>
                <SelectComponent
                  options={botList}
                  width={350}
                  defaultValue={{ label: "Tous les robots", value: "Tous les robots" }}
                  handler={setBotName}
                ></SelectComponent>
              </div>
            </nav>
            <div className="groupe-button shadow">
              <div className={`btn ${errorView ? "" : "btn-primary shadow"}`} onClick={handleBtnClick}>
                Statuts des itérations
              </div>
              <div className={`btn ${errorView ? "btn-primary shadow" : ""}`} onClick={handleBtnClick}>
                Focus erreurs
              </div>
            </div>
            <section className={"main"}>
              <div className={errorView ? "display-iterations" : "display-errors"}>
                <ErrorsFocus
                  month={month}
                  botData={userData.find((bot) => bot.botName === botName)?.data || emptyDatas}
                  aggregation={botName === "Tous les robots"}
                  errorsAggregation={errorsAggregation}
                ></ErrorsFocus>
              </div>
              <div className={!errorView ? "display-iterations" : "display-errors"}>
                <IterationStatus
                  month={month}
                  botData={userData.find((bot) => bot.botName === botName)?.data || emptyDatas}
                  aggregation={botName === "Tous les robots"}
                ></IterationStatus>
              </div>
            </section>
            <div className={"home-footer"}>
              <div className={"home-footer-version-container"}>
                {frontVersion ? <span className={"home-footer-version"}>F : {frontVersion}</span> : " "}
                {backVersion ? <span className={"home-footer-version"}>B : {backVersion}</span> : " "}
              </div>
              <HelpMenu></HelpMenu>
            </div>
          </div>
        </div>
      ) : (
        <Unknown></Unknown>
      )}
    </>
  )
}
